<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Streams</div>
        <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">{{ UnlockedTimeMsg }}</div>
      </div>
    </div>
    
    
    <b-table v-if="streamdata"
    
                :data="streamdata"
                :bordered="true"
                :striped="true"
                :narrowed="false"
                :hoverable="true"

                :loading="false"
                :focusable="true"

                :header-checkable="false"
                :mobile-cards="true"
                
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                
                >
                <!-- checkable -->
                <!-- :checked-rows.sync="tbl1_checkedRows" -->
                
                <template row-class="REDColor">
                    <b-table-column field="companies_id" label="comp id" width="30" numeric v-slot="props" centered searchable sortable>
                        <a :href='`https://medischescholing.nl/companies/${props.row.companies_id}/streams`' 
                            class='isLink' 
                            :class="calcIsExpired(props.row.course_dates_start) ? 'isExpired' : ''" 
                            target='_blank'
                            >
                          {{ props.row.companies_id }}
                        </a>
                    </b-table-column>
                    
                    <b-table-column field="companies_name" label="company" width="60" v-slot="props" searchable sortable>
                        {{ props.row.companies_name }}
                    </b-table-column>
                                        
                    <b-table-column field="courses_id" label="courses_id" width="40" numeric v-slot="props" centered searchable sortable>
                      <a :href='`https://medischescholing.nl/course/${props.row.courses_id}`' class='isLink'  target='_blank'>
                        {{ props.row.courses_id }}
                      </a>
                    </b-table-column>                    

                    <b-table-column field="course_dates_start" label="date" width="60" v-slot="props" centered searchable>
                        {{ getPrintDate(props.row.course_dates_start) }}
                    </b-table-column>
                    <b-table-column field="course_dates_start" label="time" width="80" v-slot="props" centered searchable>
                        {{ getPrintTime(props.row.course_dates_start) }}-{{ getPrintTime(props.row.course_dates_end) }}
                    </b-table-column>
                    
                    <b-table-column field="streams_id" label="streams id" width="40" numeric v-slot="props" centered searchable sortable >
                      <a :href='`https://medischescholing.nl/streams/${props.row.streams_id}`' class='isLink' target='_blank'>
                        {{ props.row.streams_id }}
                      </a>
                      <a :href='`https://medischescholing.nl/streams/${props.row.streams_id}/admin-console`' class='isLink'  target='_blank'>
                        admin
                      </a>
                      <a :href='`https://medischescholing.nl/streams/${props.row.streams_id}/edit`' class='isLink'  target='_blank'>
                        edit
                      </a>
                    </b-table-column>
                    
                    <b-table-column field="streams_title" label="streams title" width="80" v-slot="props" searchable>
                        {{ props.row.streams_title }}
                    </b-table-column>
                    
                    <b-table-column field="tickets_id" label="tickets id" width="30" numeric v-slot="props" centered searchable sortable >
                        {{ props.row.tickets_id }}
                    </b-table-column>
                    
                    <b-table-column field="tickets_parent_id" label="par id" width="30" numeric v-slot="props" centered searchable sortable >
                        {{ props.row.tickets_parent_id }}
                    </b-table-column>
                    
                    <b-table-column field="tickets_allow_guest" label="guest" width="30" numeric v-slot="props" centered searchable >
                        {{ props.row.tickets_allow_guest }}
                    </b-table-column>
                    <b-table-column field="tickets_administrator_only" label="admin" width="30" numeric v-slot="props" centered searchable>
                        {{ props.row.tickets_administrator_only }}
                    </b-table-column>
                    <b-table-column field="tickets_generic_token" label="token" width="40" v-slot="props" centered searchable>
                      <a v-if="props.row.tickets_generic_token && props.row.tickets_allow_guest !== 1" :href='`https://medischescholing.nl/ticket/${props.row.tickets_generic_token}/subscribe`' class='isLink'  target='_blank'>
                        User: {{ props.row.tickets_generic_token }}
                      </a>
                      <a v-if="props.row.tickets_generic_token && props.row.tickets_allow_guest === 1" :href='`https://medischescholing.nl/gast?webinar_token=${props.row.tickets_generic_token}`' class='isLink'  target='_blank'>
                        Guest: {{ props.row.tickets_generic_token }}
                      </a>
                        
                    </b-table-column>
                    <b-table-column field="TicketSubscribed" label="Sub#" width="40" numeric v-slot="props" centered searchable sortable >
                        {{ props.row.TicketSubscribed }}                        
                    </b-table-column>
                    
                    <b-table-column :visible="isUnlocked" field="TicketSubscribed" label="actions" width="40" v-slot="props" numeric centered searchable sortable >
                        <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                          <template #trigger>
                            <b-button label="action" type="is-primary">action</b-button>
                          </template>
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersTicketCSV(props.row.tickets_id, {type: 'email'})">ticket email CSV ticketId: {{ props.row.tickets_id }} - {{ props.row.tickets_generic_token }}</b-dropdown-item>
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersTicketCSV(props.row.tickets_id, {type: 'phonenr'})">ticket phonenr CSV ticketId: {{ props.row.tickets_id }} - {{ props.row.tickets_generic_token }}</b-dropdown-item>
                          
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersTicketExcel(props.row.tickets_id, {type: 'email'})">ticket email EXCEL ticketId: {{ props.row.tickets_id }} - {{ props.row.tickets_generic_token }}</b-dropdown-item>
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersTicketExcel(props.row.tickets_id, {type: 'phonenr'})">ticket phonenr EXCEL ticketId: {{ props.row.tickets_id }} - {{ props.row.tickets_generic_token }}</b-dropdown-item>
                          
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersCourseCSV(props.row.courses_id, {type: 'email'})">course email CSV courseId: {{ props.row.courses_id }}</b-dropdown-item>
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersCourseCSV(props.row.courses_id, {type: 'phonenr'})">course phonenr CSV courseId: {{ props.row.courses_id }}</b-dropdown-item>
                          
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersCourseExcel(props.row.courses_id, {type: 'email'})">course email EXCEL courseId: {{ props.row.courses_id }}</b-dropdown-item>
                          <b-dropdown-item aria-role="listitem" @click="downloadUsersCourseExcel(props.row.courses_id, {type: 'phonenr'})">course phonenr EXCEL courseId: {{ props.row.courses_id }}</b-dropdown-item>
                          <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
                      </b-dropdown>                
                    </b-table-column>
                    
                    
                </template>
                


                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Geen Streams gevonden.</p>
                        </div>
                    </section>
                </template>
            </b-table>
    
   
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { get_doubleDigits } from '@/helpers/functions';
import { API_ROOT } from '@/config/app.js';

export default {
  name: "Streams", 
         
  data() {
    return {
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      
      pageLoading : false,
      pageLoadingTimeout : null,
      
      streamdata: null,
      
      isPaginated: true,
      perPage: 100,
      currentPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      
      timeDiff: 0,
      
    }
  },
  
  async mounted(){
    
    // this.ShowPageLoading(15000);
    this.UpdateLockStatus();
    // this.GetTableStructureData();
    
    const _this = this;
    setInterval(() => {
      _this.CheckLockStatus();
    }, 1000);
    
    const rtn = await this.$store.dispatch('databaseactions/DB_GetStreamData');   
    console.info(`rtn:`, rtn)
    this.streamdata = rtn.payload.StreamData;
    
    // this.timeDiff = rtn.stime - new Date().getTime()
    // console.info(`timeDiff:`, this.timeDiff)
    
    
    // console.info(`streamdata:`, this.streamdata);
  },
  
  methods: {
    
    async UpdateLockStatus() {
      // console.info(`-UpdateLockStatus???`);
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },
     
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    getPrintDateTime(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        //Correct for server time difference!
        // dateobj.setTime(dateobj.getTime() - this.timeDiff)
        
        const datestr   =  dateobj.getDate() + "-" + (dateobj.getMonth() +1) + "-" + dateobj.getFullYear();
        const timestr   =  get_doubleDigits(dateobj.getHours()) + ":" + get_doubleDigits(dateobj.getMinutes());
        return datestr + " " + timestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    
    getPrintDate(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        const datestr   =  dateobj.getDate() + "-" + (dateobj.getMonth() +1) + "-" + dateobj.getFullYear();
        return datestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    getPrintTime(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        const timestr   =  get_doubleDigits(dateobj.getHours()) + ":" + get_doubleDigits(dateobj.getMinutes());
        return timestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    
    calcIsExpired(dateStr){
      try {
        let dateobj   = new Date(dateStr);
        dateobj.setTime(dateobj.getTime() + 12*60*1000);
        // console.info(`dateobj${dateStr} :`, dateobj.toDateString());
        const curDate = new Date();
        if (curDate.getTime() > dateobj.getTime()) {
          return true;
        }
        return false;
        
      } catch (err) {
        console.info("Error calcIsExpired:", err.message);
        return false;
      }
    },
    
    
    async Unlock() {
      const _this = this;
      
      if (this.isUnlocked) {
        await _this.$store.dispatch('databaseactions/DB_ClearLockStatus', { });
        await _this.UpdateLockStatus();
        return;
      }
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      // if (LockStatus.unlocked === true) {
        // this.GetTableStructureData();
      // }
      this.CheckLockStatus();
    },
    
    
    CheckLockStatus(){
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
      }
    },
    
    downloadUsersTicketCSV(ticketId, {type}){
      window.open(`${API_ROOT}/monitor/database/DownloadTicketUsers?ticketid=${ticketId}&format=csv&type=${type}`)
    },
    downloadUsersTicketExcel(ticketId, {type}){
      window.open(`${API_ROOT}/monitor/database/DownloadTicketUsers?ticketid=${ticketId}&format=excel&type=${type}`)
    },
    downloadUsersCourseCSV(courseId, {type}){
      window.open(`${API_ROOT}/monitor/database/DownloadCourseUsers?courseid=${courseId}&format=csv&type=${type}`)
    },
    downloadUsersCourseExcel(courseId, {type}){
      window.open(`${API_ROOT}/monitor/database/DownloadCourseUsers?courseid=${courseId}&format=excel&type=${type}`)
    },
        
    
  },
};
</script>

<style scoped>
  .hasError{
    color: red;
  }
  
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    color: #AEAEAE;
  }
  
  .itemtext{
    float:left;
    display: inline;
    min-width: 80px;
    font-weight: bold;
    /* background-color: orange;; */
  }
  
  
  .HashStatusOK,
  .GitStatusOK{
    color:darkgreen;
  }
  
  .HashStatusFailed,
  .GitStatusFailed{
    color: red;
  }
  
  .isLink{
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .isExpired{
    color: red;
  }
                        
</style>