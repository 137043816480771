const SecondMilliSeconds  = 1000;
const MinuteMilliSeconds  = 60000;
const HourMilliSeconds    = 3600000;
const DayMilliSeconds     = 86400000;

module.exports = {
  
  waitTime: (WaitMilliSeconds = 0) => {
    return new Promise(function (resolve) {
      setTimeout(() => {
        resolve(true);
      }, WaitMilliSeconds);
    });
  },

  hasNotificationSupport: () => {
    if ('Notification' in window) {
      return true;
    } 
    console.log('hasNotificationSupport not supported');
    return false;
  },
  
  
  get_doubleDigits: (number) => {
    if (number < 10) {
      return "0" + number;
    }
  
    return number;
  },
  
  doubleDigits: (number) => {
    if (parseInt(number) < 10) {
      return "0" + number;
    }
    return number;
  },
  
  
  Calc_TimeLeftMsg: (ItemDateTimeStamp) => {
    // console.info(`ItemDateTimeStamp:`, ItemDateTimeStamp);
    if (typeof(ItemDateTimeStamp) === "undefined" || parseInt(ItemDateTimeStamp) <= 0) {
      console.info("Calc_TimePassedMsg Unknown DateTimestamp, aborting!", ItemDateTimeStamp);
      return "";
    }
  
    const CurDate = new Date();
    const TimePassed = Math.max(0, parseInt(ItemDateTimeStamp) - CurDate.getTime());
    // console.info(`TimeLeft:`, TimePassed);
  
    const days = Math.floor(TimePassed / DayMilliSeconds);
    if (days >= 1) {
      if (days >= 2) {
        return `${days} days`
      }
      return `${days} day`    
    } 
    
    const hours = Math.floor(TimePassed / HourMilliSeconds);
    if (hours >= 1) {    
      if (hours >= 2) {
        return `${hours} hours`
      }
      return `${hours} hour`
    }
    
    const minutes = Math.floor(TimePassed / MinuteMilliSeconds);
    if (minutes >= 1)  {
      if (minutes >= 2) {
        return `${minutes} minutes`
      }
      return `${minutes} minute`
    } 
    
    return Math.floor(TimePassed / SecondMilliSeconds) + " seconds";
  },
  
  getTimeStr: (val) => {
    let left    = val;
    let hours   = Math.floor(left / 3600)
    left = left - (hours * 3600)
    
    let minutes = Math.floor(left / 60)
    let seconds = Math.floor(left % 60)
    
    if (hours > 0) {
      return `${module.exports.doubleDigits(hours)}:${module.exports.doubleDigits(minutes)}:${module.exports.doubleDigits(seconds)}`
    }
    
    return `${module.exports.doubleDigits(minutes)}:${module.exports.doubleDigits(seconds)}`        
  },

  FetchJSONData: async (url, options = {}, FailVal = false) => {
    try {
      let resp = await fetch(url, options);  
      if (resp.status !== 200) {
        return FailVal;
      }
      
      return resp.json();
      
    } catch (err) { 
      console.info(`Error FetchJSONData:`, err.message); 
      return FailVal;
    }
  },

  FetchTextData: async (url, options = {}, FailVal = false) => {
    try {
      let resp = await fetch(url, options);  
      if (resp.status !== 200) {
        return FailVal;
      }
      
      return resp.text();
      
    } catch (err) { 
      console.info(`Error FetchTextData:`, err.message); 
      return FailVal;
    } 
  },
  
  
  
}
